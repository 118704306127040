import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// Styles SCSS
import '../sass/home.scss';

import 'svgxuse';

// LazyLoad
import lazyLoadInit from './lazyload-init';
lazyLoadInit();

import 'slick-carousel';

import 'jquery.marquee';
import Swiper from 'swiper';

// pushbar
import Pushbar from './utils/pushbar';

$(document).ready(() => {
	// Toggle unclic.twig + change le chevron
	$('#demarches').click(function() {
		$('#unclic').toggle();
		$('#unclic').toggleClass('active');
		$(this).toggleClass('rotate');
	});

	$(function() {
		var $acces = $('#sticky-access'),
			$connect = $('#sticky-connect');

		$acces.find('.trigger').on('click', function(event) {
			event.preventDefault();
			$acces.toggleClass('active');
			$connect.removeClass('active');
		});

		$connect.find('.trigger').on('click', function(event) {
			event.preventDefault();
			$connect.toggleClass('active');
			$acces.toggle('slide');
			$acces.removeClass('active');
		});
	});

  var swiperLeft = new Swiper('.swiper-left', {
  effect: 'coverflow',
  grabCursor: true,
  mousewheel: true,
  centeredSlides: true,
  initialSlide: 1,
  slidesPerView: 3,
  slideToClickedSlide: true,
  coverflowEffect: {
    rotate: 50,
    stretch: 0,
    depth: 200,
    modifier: 1,
    slideShadows : false,
  },
});

var swiperRight = new Swiper('.swiper-right', {
  effect: 'fade'
});

swiperLeft.on('slideChange', function() {
  swiperRight.slideTo(swiperLeft.activeIndex)
})



	// $('#publication_2').hover(
	//   function() {
	//   $(this).toggleClass('invisible');
	//   $('.visible').toggleClass('visible');
	//   $(this).toggleClass('visible');

	// },
	// function() {
	//   $(this).toggleClass('invisible');
	//   $('.visible').toggleClass('visible');
	//   $(this).toggleClass('visible');
	// });

	$('.ticker').marquee({
		duration: 13000,
		delayBeforeStart: 0,
		direction: 'left',
		duplicated: false,
		pauseOnHover: true
	});

	$('.actualites__slider').slick({
		//dots: true,
		infinite: true,
		arrows: true,
		autoplay: false,
		autoplaySpeed: 2000,
		//appendDots: '.actualites__dots',
		nextArrow: '<i class="fas fa-chevron-right slick-arrow__right"></i>',
		prevArrow: '<i class="fas fa-chevron-left slick-arrow__left"></i>'
	});

	new Pushbar({
		blur: true,
		overlay: true
	});

	jQuery('.header-search__icon').on('click', function() {
		jQuery(this).addClass('active');
		jQuery(this)
			.find('input')
			.focus();
	});
});
